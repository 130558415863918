/*
 * @Author: 荀厥樟
 * @Date: 2021-06-29 09:18:02
 * @LastEditTime: 2021-11-09 11:10:56
 * @LastEditors: Please set LastEditors
 * @Description: 接口索引页
 * @FilePath: \SuperMall\src\common\api\index.js
 */

import base from './base';
import activity from './activity';
import receivePayment from './receivePayment.js';

export default {
	base,
	activity,
	receivePayment
};
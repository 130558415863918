/*
 * @Author: your name
 * @Date: 2021-08-05 16:52:04
 * @LastEditTime: 2021-11-05 09:34:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Basic\src\common\api\user.js
 */

import domain from './domain'
import { get, post } from './axios'

const base = {
}

export default base

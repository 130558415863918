/*
 * @Author: your name
 * @Date: 2021-08-05 16:52:04
 * @LastEditTime: 2021-11-09 17:19:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Basic\src\client\router.js
 */
import Vue from 'vue';
import VueRouter from 'vue-router';

import Activity from './pages/Activity';
import Record from './pages/Record';
import receiveRecord from './pages/receiveRecord';
import receivePayment from './pages/receivePayment';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function(location)
{
	return originalPush.call(this, location).catch(err => err);
};

const routes = [
	// {
	// 	path: '/activity/:code',
	// 	name: 'Activity',
	// 	component: Activity
	// },
	// {
	// 	path: '/record',
	// 	name: 'Record',
	// 	component: Record
	// },
	{
		path: '/:activityId',
		name: 'receivePayment',
		component: receivePayment,
	},
	{
		path: '/receiveRecord/:activityId',
		name: 'receiveRecord',
		component: receiveRecord
	}
];

const router = new VueRouter({
	// mode: 'history',
	routes
});

export default router;
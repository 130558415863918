
import domain from "./domain";
import { get, post } from "./axios";



const receivePayment = {
    getActivityInfo(data) {
        return get(`${domain.mobile}/getActivityInfo/{activityId}`, data);
    },
    //获取通行证
    userLogin(data) {
        return get(`${domain.mobile}/login/{activityId}/{authCode}`, data);
    },
    receivePay(data) {
        return post(`${domain.mobile}/receive`, data);
    },
    record(data){
        return get(`${domain.mobile}/record`, data,[ "record" ]);
    }
};

export default receivePayment;

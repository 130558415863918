<!--
 * @Author: your name
 * @Date: 2021-08-05 16:52:04
 * @LastEditTime: 2022-03-01 11:31:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Basic\src\client\pages\CouponDetail.vue
-->
<template>
	<div>
		this is activity page {{ code }}
	</div>
</template>

<script>
export default {
	name: "Activity",
	data()
	{
		return {
			code: this.$route.params.code
		};
	},
	created()
	{
		this.$api.activity.details({ code: this.code })
			.then(response =>
			{
				console.log('success', response);
			})
			.catch(response =>
			{
				console.log('error', response);
			});
	},
	mounted()
	{
	},
	methods: {
	}
};
</script>

<style scoped>
</style>

<template>
  <div  class="receive-payment" v-if="authCode && isZFB && activeStatus == 0" :style="{ 'backgroundImage': `url(${activityInfo.themeImg})`}">
    <template v-if="activityInfo.phone?(active == 0||active == 1):active == 0" >
      <div>
        <div class="call-btn" v-show="activityInfo.rule" @click="showRule" :style="{background:activityInfo.themeColor?activityInfo.themeColor:'#DD4535'}">活动规则</div>
        <div type="primary" v-show="activityInfo.policy" class="privacy-btn" @click="showPrivacy" :style="{background:activityInfo.themeColor?activityInfo.themeColor:'#DD4535'}">隐私政策</div>
      </div>
      <van-button type="primary" class="receive-btn" :loading="receiveLoading" loading-type="spinner"  :loading-text="activityInfo.btnName?activityInfo.btnName:'立即领取'" :style="{background:activityInfo.themeColor?activityInfo.themeColor:'#DD4535'}" @click="receivePay">{{ activityInfo.btnName?activityInfo.btnName:'立即领取'}}</van-button>
      <van-popup v-model="ruleVisible" closeable :close-on-click-overlay="false" class="vant-pop">
        <div class="pop-box">
          <span class="pop-title">活动规则</span>
          <span class="pop-content ql-editor ql-bubble" v-html="activityInfo.rule"></span>
        </div>
      </van-popup>
      <van-popup v-model="policyVisible" closeable :close-on-click-overlay="false" class="vant-pop">
        <div class="pop-box">
          <span class="pop-title">隐私政策</span>
          <span class="pop-content">{{ activityInfo.policy }}</span>
        </div>
      </van-popup>
    </template>
    <receive-record v-if="activityInfo.phone?active == 2:active == 1" :UserToken="UserToken"></receive-record>
    <van-tabbar v-model="active" class="receive-footer" :active-color="activityInfo.themeColor?activityInfo.themeColor:'#DD4535'">
      <van-tabbar-item icon="wap-home-o">首页</van-tabbar-item>
      <van-tabbar-item v-if="activityInfo.phone" icon="phone-circle-o" @click="callPhone(activityInfo.phone)">客服</van-tabbar-item>
      <van-tabbar-item icon="coupon-o">领取记录</van-tabbar-item>
    </van-tabbar>
  </div>


  <div v-else-if="isZFB && activeStatus ==1" class="empty-custom">
    <img src="../assets/image/noExist.png">
    <div class="empty-size">活动不存在！</div>
  </div>

  <div v-else-if="isZFB && activeStatus ==2" class="empty-custom">
    <img src="../assets/image/noStart.png">
    <div class="empty-size">您来早啦，活动还尚未开始！</div>
  </div>

  <div v-else-if="isZFB && activeStatus ==3 " class="empty-custom">
    <img src="../assets/image/timed.png">
    <div class="empty-size">您来迟啦，活动已结束！</div>
  </div>


  <div class="receive-payment" v-else-if="!authCode && isAuth && isZFB" :style="{ 'backgroundImage': `url(${activityInfo.themeImg})`}">
    <van-popup v-model="showTip" :close-on-click-overlay="false" style="height:255px;text-align: center;padding: 20px;border-radius: 8px">
      <img src="../assets/image/warning.png"/>
      <div style="color:#222222;line-height: 36px">暂无权限</div>
      <span style="padding:10px 0 20px 0;color:#999999">请授权之后再操作！</span>
      <van-button type="default" class="login-btn" :style="{background:activityInfo.themeColor?activityInfo.themeColor:'#00A5FF'}"  @click="initLogin">重新授权</van-button>
    </van-popup>
  </div>

  <div class="err-tip" v-else-if="!isZFB">
    <div class="err-box">
      <van-icon class="errorImg" name="info-o" />
    </div>
    <p class="tipText">请在支付宝客户端打开链接</p>
  </div>
</template>

<script>
import { Dialog,Toast,Icon,Popup  } from 'vant';
import receiveRecord from './receiveRecord.vue'
export default {
  name: "receivePayment",
  components: {
    [Dialog.Component.name ]: Dialog.Component,
    receiveRecord
  },
  data() {
    return {
      active:0,
      activityInfo:{},
      authCode:null,
      isZFB:false,
      receiveLoading:false,
      isAuth:false,//是否授权了
      ruleVisible:false,
      policyVisible:false,
      activeStatus:null, //0存在且在时间内  1不存在  2未开始 3已结束
      UserToken:null

    };
  },
  computed:{
    activityId() {
      return this.$route.params.activityId
    },
    showTip(){
      return !this.authCode
    }
  },
  created() {
    this.checkZFB()
    Toast.setDefaultOptions({ duration: 3000 });

  },

  methods:{
    checkZFB(){
      var ua = window.navigator.userAgent.toLowerCase();
      if(ua.match(/AlipayClient/i) == 'alipayclient'){
        console.log('我是支付宝',this)
        this.isZFB = true
        this.getActivityInfo()
      }else{
        console.log('我不不是支付宝',this)
      }
    },
    getActivityInfo(){
      let _this = this
      this.$api.receivePayment.getActivityInfo({activityId: this.activityId})
          .then(res => {
            if(res.code == 200){
              this.activityInfo = res.data
              this.activeStatus = 0
              this.initLogin()
            }else if(res.code == 201){
             this.activeStatus = 2 //未开始
            }else if(res.code == 202){
              this.activeStatus = 3  //已结束
            }if(res.code == 203){
              this.activeStatus = 1  //不存在
            }
          })
          .catch(res => {
            Toast.fail(res.msg)
          })
    },
    userLogin(appId,authCode){
      this.$api.receivePayment.userLogin({activityId: this.activityId,authCode:authCode})
          .then(res => {
            if(res.code == 200 && res.msg){
              this.UserToken = res.msg
              sessionStorage.setItem('UserToken',res.msg)
            }
          })
          .catch(err => {
            console.log(err)
          });
    },
    receivePay(){
      if(this.receiveLoading){
        return
      }
      this.receiveLoading = true
      let _this = this
      this.$api.receivePayment.receivePay()
          .then(res => {
            if(res.code == 200){
              Toast.success('领取成功');
              setTimeout(()=>{
                if(_this.activityInfo.phone){
                  _this.active = 2
                }else{
                  _this.active = 1
                }
              },2000)
            }else{
              Toast.fail(res.msg)
            }
          })
          .catch(res => {
            Toast.fail(res.msg)
          }).finally(()=>{
              _this.receiveLoading = false
          })

    },

    initLogin(){
      let _this = this
      ap.getAuthCode ({
        appId :  this.activityInfo.appId ,
        scopes : ['auth_user'],
      },function(res){
        _this.isAuth = true
        _this.authCode = res.authCode
        _this.userLogin(_this.activityInfo.appId,_this.authCode)
      });

    },
    showDetail(){
      this.$router.push({
        path:`/receiveRecord/${this.activityId}`,
        // query:JSON.stringify(this.activityInfo.activityId)
        query: {
          activityId:this.activityInfo.activityId,
          UserToken:sessionStorage.getItem('UserToken')
        }
      })
    },
    showPrivacy(){
      this.policyVisible = true
    },
    showRule(){
      this.ruleVisible = true
    },
    callPhone(phoneNumber) {
      console.log(phoneNumber);
      window.location.href = "tel://" + phoneNumber;
    },
  },
}

</script>
<style scoped>
.receive-payment{
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/image/back.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  margin:0px;
  background-attachment:fixed;
  display: flex;
  /*align-items: center;*/
  justify-content: center;
}
.empty-custom{
  display: flex;
  justify-content: center;
  padding-top: 150px;
  flex-direction: column;
  align-items: center;
  /*height: 100vh;*/
}
.empty-size{
  color: #B5B6B8;
  font-size: 18px;
}
.login-btn{
  margin-top:35px;
  width: 180px;
  color: #fff;
  border-radius: 5px;
  font-size: 18px;
}
 /deep/ .van-popup__close-icon--top-right{
  /*top:0!important;*/
  /*right: 0;*/
  /*background: darkgray;*/
   right: -8px;
   background: #212121;
   border-radius: 3px;
   /* bottom: -30px; */
   top: -6%;
   font-size: 22px;
   opacity: 0.9;
   color: #fff;
   padding:5px;
   border-radius: 50%;
   border: 1px solid #212121;

}
 .vant-pop{
   border-radius: 5px;
   width: 80%;
   max-height: 39%;
   min-height: 30%;
   overflow: visible;
 }
.receive-footer{
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  /*padding-bottom: 5px;*/
  height: 70px;
}
.call-btn{
  width: 27px;
  /*background: #EA8047;*/
  /*background: #DD4535;*/
  position: absolute;
  top: 122px;
  right: 0px;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  padding: 9px 3px;
}

.privacy-btn{
  width: 27px;
  /*background: #EA8047;*/
  position: absolute;
  right: 0px;
  top: 239px;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  padding: 9px 3px;

}
.tipText {
  font-size: 17px;
  color: #333;
  text-align: center;
}
.err-tip{
  /*display: flex;*/
  /*align-items: center;*/
  /*justify-content: center;*/
}
.pop-box{
  padding:8px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin: 0 8px 8px 12px;
}
.pop-content{
  color: grey;
  max-height: 200px;
  overflow: auto;
  /*padding: 8px;*/
  line-height: 26px;
  color: grey

}
.pop-title{
  font-weight: 600;
  line-height: 50px;
  font-size: 18px;
  text-align: center;
}
.err-box{
  display: flex;
  align-items: center;
  justify-content: center;

}
.errorImg {
  padding: 60px 0px 35px;
  text-align: center;
  font-size: 100px;
  color: #E6A23C;
}

.receive-btn{
  position: absolute;
  bottom: 120px;
  /*left: 50px;*/
  /*margin:0 auto ;*/
  /*background: ;*/
  font-size: 22px;
  padding: 28px 112px;
  border-radius: 2px;
  border: none;
}

</style>
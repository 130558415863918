/*
 * @Author: your name
 * @Date: 2021-08-05 16:52:04
 * @LastEditTime: 2022-09-02 10:45:21
 * @LastEditors: xunmi muer3mu@126.com
 * @Description: In User Settings Edit
 * @FilePath: \Basic\src\common\api\user.js
 */

import domain from "./domain";
import { get, post } from "./axios";

const ActivityDetailsFormat = (data) =>
{
	return data;
};

const ActivityRecordFormat = (list) =>
{
	if (list && list.length)
	{
		/* list.forEach((item) =>
		{
			
		}); */
	}

	return list;
};

const activity = {
	details(data, bag)
	{
		return get(`${domain.mobile}/activity/{code}`, data, [ "code" ], ActivityDetailsFormat, bag);
	},
	record(data, bag)
	{
		return get(`${domain.mobile}/activity/getReceive`, data, [ "activityId", "openId" ], ActivityRecordFormat, bag);
	}
};

export default activity;

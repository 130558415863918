<template>
   <div class="receive-record">
<!--     <van-loading v-if="loading" style="width: 100vw;height: 100vh;display: flex;justify-content: center;align-items: center"/>-->
<!--     <van-tabs  v-else v-model="active" @change="tabChange">-->
     <van-tabs  v-model="active" @change="tabChange" sticky >
       <van-tab :title="`可用（${ableCount}）`" class="record-tab">
         <van-empty v-if="coupons.length == 0" description="暂无优惠券" />

           <van-list v-else class="tab-content" :loading="loading" :finished="finishedAbled" finished-text="没有更多了" @load="onLoad">
             <div>
               <div v-for="coupon in coupons" >
                 <div class="single-box">
                   <div class="left-box">
                     <div class="top-amount">{{ coupon.sendAmount }}元立减金</div>
                     <div class="center-time">领取时间：{{ coupon.receiveTime }}</div>
                     <div class="center-time">到期时间：{{ coupon.lifespanEnd }}</div>
                     <span class="bottom-amount">满{{ coupon.discountThresholdAmt }}可减{{ coupon.sendAmount }}</span>
                   </div>
                   <div class="right-box">￥<span class="right-content">{{ coupon.sendAmount }}</span></div>
                 </div>
               </div>
             </div>
           </van-list>
       </van-tab>




       <van-tab :title="`不可用（${disableCount}）`" class="record-tab">
         <van-empty v-if="disabledCoupons.length == 0" description="暂无优惠券" />
         <van-list v-else class="tab-content" :loading="loadingDis" :finished="finishedDisabled" finished-text="没有更多了" @load="onLoadDis">
         <div>
           <div v-for="disabledCoupon in disabledCoupons">
             <div class="single-box">
               <div class="left-box">
                 <div class="top-amount">{{ disabledCoupon.sendAmount }}元立减金</div>
                 <div class="center-gettime">领取时间：{{ disabledCoupon.receiveTime?disabledCoupon.receiveTime:'-' }}</div>
                 <span class="bottom-amount">满{{ disabledCoupon.discountThresholdAmt }}可减{{ disabledCoupon.sendAmount }}</span>
               </div>
               <div class="right-box-disabled">￥<span class="right-content">{{ disabledCoupon.sendAmount }}</span></div>
               <div class="tag" v-if="disabledCoupon.zfbqUseStatus == 3">已使用</div>
               <div class="tag" v-if="disabledCoupon.zfbqUseStatus == 4">已过期</div>
               <div class="tag" v-if="disabledCoupon.zfbqUseStatus == 1">领取中</div>
<!--               <div class="tag" v-if="disabledCoupon.zfbqUseStatus == 0" style="top: 15px;left:53%;">领取失败</div>-->
             </div>
           </div>
         </div>
         </van-list>
       </van-tab>
     </van-tabs>

   </div>
</template>
<script>
import {Toast} from "vant";
export default {
  props:{
    UserToken:{
      type:String,
      default:null
    }
  },
  data(){
    return {
      chosenCoupon: 1,
      coupons: [],
      disabledCoupons: [],
      active:'可用',
      loading:false,
      loadingDis:false,
      finishedAbled: false,
      finishedDisabled: false,
      currentPage: 1, //当前页
      currentPageDis:1,
      pageSize:10,
      ableCount:0,
      disableCount:0,
      allCount:0

    }
  },
  created() {
    this.initAble()
    this.initDisabled()
  },
  methods: {
    initAble(){
      sessionStorage.setItem('UserToken',this.UserToken)
      this.$api.receivePayment.record({pageNum:this.currentPage,pageSize:this.pageSize,reasonable:false,voucherStatus:'ENABLED'}).then(res=>{
        console.log(res.rows.length,'res.rows')
        this.ableCount = res.total
        if(res.rows.length==0){
          this.finishedAbled = true;
        }
        res.rows && res.rows.forEach(p=>{
            this.coupons.push(p)
        })
        // 如果list长度大于等于总数据条数，数据全部加载完成
        if(this.coupons >= res.total){
          this.finishedAbled = true;//结束加载
        }
        console.log(this.coupons,'this.coupons',this.coupons.length)

      }).catch(e=>{
        Toast.fail('获取领取记录失败！请联系管理员')
      }).finally(()=>{
        this.loading = false
      })
    },
    initDisabled(){
      sessionStorage.setItem('UserToken',this.UserToken)
      this.$api.receivePayment.record({pageNum:this.currentPageDis,pageSize:this.pageSize,reasonable:false,voucherStatus:'DISABLED'}).then(res=>{
        console.log(res.rows.length,'res.rows')
        this.disableCount = res.total
        if(res.rows.length==0){
          this.finishedDisabled = true;
        }
        res.rows && res.rows.forEach(p=>{
            this.disabledCoupons.push(p)
        })
        // 如果list长度大于等于总数据条数，数据全部加载完成
        if(this.disabledCoupons >= res.total){
          this.finishedDisabled = true;//结束加载
        }

      }).catch(e=>{
        Toast.fail('获取领取记录失败！请联系管理员')
      }).finally(()=>{
        this.loadingDis = false
      })

    },

    onLoad() { // 若加载条到了底部
        this.currentPage++;					// 分页数加一
        this.initAble(); 	// 调用上面方法,请求数据
        this.loading = true
    },
    onLoadDis() { // 若加载条到了底部
      this.currentPageDis++;					// 分页数加一
      this.initDisabled(); 	// 调用上面方法,请求数据
      this.loadingDis = true
    },
    tabChange(active){
      // this.init()
    }

  }
}
</script>
<style scoped>
.receive-record{
  width: 100%;
  height: 100%;
  background: #F7F8FA;
  /*position: relative;*/
}
/*.receive-record /deep/ .van-tabs__nav{*/
/*  */
/*  !*top: 30px;*!*/
/*  background: pink;*/
/*  !*position: fixed;*!*/
/*  top: 0;*/
/*  !*left: 0*!*/
/*}*/
.tab-content{
  height: 100%;
  flex: 1;  /* box中剩余空间分配给content */
  overflow: auto;
}
.single-box{
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  background: #fff;
  margin: 15px 12px;
  overflow: hidden;
  position: relative;

  /*padding: 16px;*/
}
.left-box{
  padding: 10px 0 10px 23px;
}
.top-amount{
  color: #000;
  font-size: 16px;
  line-height: 38px;
  font-weight: 600;
}
.center-time{
  color: #C1C1C1;
  font-size: 14px;
  line-height: 20px;
}
.center-gettime{
  color: #C1C1C1;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 20px;
}
.bottom-amount{
  color: #C1C1C1;
  font-size: 14px;
  line-height: 38px;
}
.right-box{
  background: #DD4535;
  color: #fff;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:0 10px 10px 0 ;

}
.right-box-disabled{
  background: #C9C9C9;
  color: #fff;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:0 15px 15px 0 ;

}
.tag{
    text-align: center;
    position: absolute;
    background-color: #DD4535;
    color: #fff;
    width: 80%;
    height: 18px;
    line-height: 18px;
    top: 10px;
    left:54%;
    transform: rotate(45deg);
    /*border:1px solid #000;*/
}
.right-content{
  font-size: 32px;
}
.receive-record /deep/ .van-coupon-list__exchange-bar{
    display: none;
  }
.receive-record /deep/ .van-coupon-list__bottom{
  display: none;
}
.receive-record /deep/  .van-checkbox.van-coupon__corner{
  display: none;
}
.record-tab{
  height: calc(100vh - 110px);
  display: flex;
  flex-direction: column;
  overflow: hidden;

}

.single-box{
  display: flex;
}

</style>
<!--
 * @Author: your name
 * @Date: 2021-08-05 16:52:04
 * @LastEditTime: 2022-01-17 17:23:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Basic\src\client\pages\CouponList.vue
-->
<template>
	<div>
		this is record page
	</div>
</template>

<script>
export default {
	name: 'Record',
	data()
	{
		return {
		}
	},
	created()
	{
		
	},
	mounted()
	{
	},
	methods: {
		
	}
}
</script>

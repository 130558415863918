/*
 * @Author: your name
 * @Date: 2021-08-05 16:52:04
 * @LastEditTime: 2021-11-30 16:52:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Basic\src\client\main.js
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Vant from 'vant';
import 'vant/lib/index.css';


// import zfb from "https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js"
import api from '@/common/api';
import utils from '@/common/utils';
import '@/assets/css/quill.css'
Vue.use(Vant);

import VConsole from 'vconsole';
// 只有在开发环境下才加载vconsole
// process.env.NODE_ENV === 'development' && new VConsole();


Vue.prototype.$api = api;
Vue.prototype.$utils = utils;

new Vue({
	router,
	render: h => h(App)
}).$mount('#app');

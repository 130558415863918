import axios from "axios";
import Qs from "qs";

import { encode } from "../crypto";

const createToken = (timestamp) =>
{
	const sign = "yuze";
	return encode(sign + timestamp);
};

axios.defaults.timeout = 1000 * 30;

axios.defaults.paramsSerializer = (params) => Qs.stringify(params, { arrayFormat: "brackets" });

axios.interceptors.request.use(
	(config) =>
	{
		const timestamp = new Date().getTime();

		const UserToken = sessionStorage.getItem('UserToken')
		if(UserToken){
			config.headers.UserToken = UserToken
		}

		// config.headers[ "tokens" ] = createToken(timestamp);
		config.headers[ "timestamp" ] = timestamp;

		return config;
	},
	(error) => Promise.error(error)
);

axios.interceptors.response.use(
	(response) => {
		if (response.data instanceof Blob) {
			return response;
		} else if (response.status === 200) {
			return response;
		} else {
			return Promise.reject(response);
		}
	},
	(error) =>
	{
		const { response } = error;

		if (response)
		{
			return Promise.reject(response);
		}
		else
		{
			return Promise.reject({ status: 0, message: "网络异常请稍后再试", size: "4.5vw" });
		}
	}
);

const types = {
	string: String,
	number: Number,
	boolean: Boolean,
	date: Date
};

/**
 * @description: 解析模式请求参数
 * @param {*}
 * @return {*}
 */
const getFieldAndDefault = (field) =>
{
	let result = /:(\S*)/i.exec(field);

	if (result && result[ 1 ])
	{
		let type = types[ result[ 1 ].toLowerCase() ];

		if (type)
		{
			result = /(\S+)=(\S*):/i.exec(field);
			return { key: result[ 1 ], value: type(result[ 2 ]) };
		}
	}

	result = /(\S+)=(\S*):*/i.exec(field);

	return result && result[ 1 ] && result[ 2 ] ? { key: result[ 1 ], value: result[ 2 ] } : undefined;
};

/**
 * @description: 处理restFul风格接口路由及参数
 * @param {*}
 * @return {*}
 */
const formatRequestData = (url, params, fields) =>
{
	params = params || {};

	if (fields && fields.length)
	{
		fields.forEach((field) =>
		{
			let result = getFieldAndDefault(field);

			if (result && params[ result.key ] === undefined)
			{
				params[ result.key ] = result.value;
			}
		});
	}

	for (let key in params)
	{
		let regex = new RegExp("{" + key + "}", "gi");

		url = url.replace(regex, ($0) =>
		{
			let value = params[ key ];
			delete params[ key ];

			return value;
		});
	}

	return { target: url, data: params };
};

export const get = (url, params, fields, format, bag) =>
{
	let { target, data } = formatRequestData(url, params, fields);

	return new Promise((resolve, reject) =>
	{
		axios
			.get(target, { params: data })
			.then((response) =>
			{
				format && typeof format === "function" ? resolve(response && response.data ? format(response.data) : response) : resolve(response && response.data ? response.data : response);
				bag && typeof bag === "function" && bag(response);
			})
			.catch((error) => reject(error));
	});
};

export const post = (url, params, fields, format, bag) =>
{
	let { target, data } = formatRequestData(url, params, fields);

	return new Promise((resolve, reject) =>
	{
		axios
			.post(target, data)
			.then((response) =>
			{
				format && typeof format === "function" ? resolve(response && response.data ? format(response.data) : response) : resolve(response && response.data ? response.data : response);
				bag && typeof bag === "function" && bag(response);
			})
			.catch((error) => reject(error));
	});
};

export const put = (url, params, fields, format, bag) =>
{
	let { target, data } = formatRequestData(url, params, fields);

	return new Promise((resolve, reject) =>
	{
		axios
			.put(target, data)
			.then((response) =>
			{
				format && typeof format === "function" ? resolve(response && response.data ? format(response.data) : response) : resolve(response && response.data ? response.data : response);
				bag && typeof bag === "function" && bag(response);
			})
			.catch((error) => reject(error));
	});
};

export const remove = (url, params, fields, format, bag) =>
{
	let { target, data } = formatRequestData(url, params, fields);

	return new Promise((resolve, reject) =>
	{
		axios
			.delete(target, data)
			.then((response) =>
			{
				format && typeof format === "function" ? resolve(response && response.data ? format(response.data) : response) : resolve(response && response.data ? response.data : response);
				bag && typeof bag === "function" && bag(response);
			})
			.catch((error) => reject(error));
	});
};

export const postDown = (url, params, fields, format, bag) =>
{
	let { target, data } = formatRequestData(url, params, fields);

	return new Promise((resolve, reject) =>
	{
		axios
			.post(target, data, { responseType: "blob" })
			.then((response) =>
			{
				format && typeof format === "function" ? resolve(response && response.data ? format(response.data) : response) : resolve(response && response.data ? response.data : response);
				bag && typeof bag === "function" && bag(response);
			})
			.catch((error) => reject(error));
	});
};

export const getDown = (url, params, fields, format, bag) =>
{
	let { target, data } = formatRequestData(url, params, fields);

	return new Promise((resolve, reject) =>
	{
		axios
			.get(target, { params: data, responseType: "blob" })
			.then((response) =>
			{
				format && typeof format === "function" ? resolve(response && response.data ? format(response.data) : response) : resolve(response && response.data ? response.data : response);
				bag && typeof bag === "function" && bag(response);
			})
			.catch((error) => reject(error));
	});
};

<!--
 * @Author: your name
 * @Date: 2021-08-05 16:52:04
 * @LastEditTime: 2022-03-10 10:55:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Basic\src\client\App.vue
-->
<template>
  <div>
<!--    我是新项目-->
    <router-view />
<!--    <router-link :to="'/' + activityId" />-->
<!--    <receive-payment></receive-payment>-->
  </div>
<!--	<router-view />-->
</template>

<script>
// import quill from '@/assets/css/quill.css'
import receivePayment from '@/pages/receivePayment.vue'
export default {
	name: "App",
  components:{
    receivePayment
  },
	data()
	{
		return {
      // activityId:null
		};
	},
	watch: {
		$route: {
			handler: function (to, from)
			{
				window.scrollTo(0, 0);
			},
			deep: true
		}
	},
	methods: {
	}
};
</script>
